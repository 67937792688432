import React, {useContext, useState} from 'react'
import _ from 'lodash'
import {Button, Menu, Search} from 'semantic-ui-react'
//
import {DepartementsContext, ListeStmContext} from '../../context/appContext'
//
const initialState = {isLoading : false, results : {}, value : '', selected : null}

function setSource(tableauObjets, property, groups){
    return tableauObjets.reduce(function (acc, obj) {
        let key = obj[property]
        let keyName = groups.find( x => x.value === key ).label
        if(!acc[keyName]){
            acc[keyName] = {
                name : keyName,
                results: []
            };
        }
        obj.title = obj.description_fr_cal
        acc[keyName].results.push(obj);
        return acc;
    }, {});
}

function performFind (arr, expression){
    const re = new RegExp(_.escapeRegExp(expression), 'i')
    const isMatch = (result) => re.test(result.title)
    return _.reduce(
        arr,
        (memo, data, name) => {
            const results = _.filter(data.results, isMatch)
            if (results.length) memo[name] = {name, results} // eslint-disable-line no-param-reassign
            return memo
        },
        {},
    )
}


export default function RequisitionFormSearchToolsMenuItems (props)  {
    const [stateObj, setStateObj] = useState({...initialState})
    const {listeStm} = useContext(ListeStmContext)
    const {departements} = useContext(DepartementsContext)
    const source = setSource(listeStm, "fkid_departement", departements )
    //props
    const {handleSelection, noResultOptionBtn} = props
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleResultSelect = (e, { result }) => {
        window.scrollTo(0, 0)
        handleSelection(result.recordId)
        setStateObj({...initialState})
    }
    const handleSearchChange = (e, {value}) => {
////////Pseudo code
        //Si value est vide ou qu'il n'y a qu'un seul caratère et que c'est un " ". Alors resset state
        value.length === 0 || (value.length === 1 && value.charAt(0) === " ") ?  setStateObj({...initialState}) :
        //Sinon Si les deux derniers caratèes sont des " ". L'utilisateur est avisé qu'il ne peu saisir deux espaces de suite. Le champs value est défini avec un espace en moins que la valeur saisi par l'utilisateur.
        value.charAt(value.length - 1) === " " && value.charAt(value.length - 2) === " " ?
            setStateObj({...initialState, value: value.substring(0, value.length - 1)}) :
        //Sinon si il y a plus que un caractères et que le dernié caratère saisi est un " ", le champs value de l'état est mis à jour
        value.charAt(value.length - 1) === " " ?
            setStateObj(prevStateObj => { return {...prevStateObj, value: value}}) :
        //Sinon si il y a plus que un caractères et que le dernié caratère saisi n'est pas un " ", le champs value de l'état est mis à jour et l'état loading est vrai
            setStateObj((prevStateObj) => { return {...prevStateObj, isLoading: true} })
        //
        const valueInputArray  = value.charAt(value.length - 1) === " " ? value.substring(0, value.length -2 ).split( ' ') : value.split( ' ')
        const newResult =  value.length > stateObj.value.length ?
            performFind(Object.keys(stateObj.results).length > 0 ? stateObj.results : source, valueInputArray[valueInputArray.length -1]) : valueInputArray.reduce( performFind, source)
        const newState = {results : newResult, isLoading: false, value: value}
        return setStateObj({...newState})
    }//end of handelChange
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderNoResultOptionBtn = () => {
        console.log("result lenght : ", Object.keys(stateObj.results).length)
        if (stateObj.value.length >= 1) return <Menu.Item content={noResultOptionBtn}/>
        return <></>
    }
//
//render
        return (<>
            <Menu.Item content={
                <Search
                    fluid
                    category
                    loading={stateObj.isLoading}
                    onResultSelect={handleResultSelect}
                    onSearchChange={_.debounce(handleSearchChange, 500, {leading: true,})}
                    placeholder={"Rechercher et ajouter"}
                    results={stateObj.results}
                    value={stateObj.value} />
            }/>
            {renderNoResultOptionBtn()}
        </>)
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}