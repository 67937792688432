import React from 'react'
import {Icon, Menu, Table} from "semantic-ui-react"

const PaginationTableFooter = (props) => {
    const {colCount, count, isActive, handleClick} = props
    return (
    <Table.Footer>
        <Table.Row>
            <Table.HeaderCell colSpan={colCount}>
                <Menu floated='right' pagination>
                    <Menu.Item as='a' icon={'chevron left'}/>
                    <PageItems count={count} isActive={isActive} handleClick={handleClick}/>
                    <Menu.Item as='a' icon={'chevron right'}/>
                </Menu>
            </Table.HeaderCell>
        </Table.Row>
    </Table.Footer>
)}


const PageItems = (props) => {
    const {count, isActive, handleClick} = props
    let itemsArr = new Array();
    for (let i = 1; count >= i ; i++) itemsArr.push(<Menu.Item
                                                    id={i}
                                                    as={'a'}
                                                    active={isActive === i ? true : false}
                                                    onClick={e => handleClick(e.target.id)}
                                                    content={i}
                                                />
    ); return itemsArr
}

export default PaginationTableFooter