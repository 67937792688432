//Librarys Imports
import React, {useState} from "react";
import {BrowserRouter  as Router, Switch, Route} from "react-router-dom";
//
import {UserContext, ProjectContext, DepartementsContext, ListeStmContext} from "./context/appContext";
//UI library imports
import {Container, Form, Checkbox, Button} from "semantic-ui-react";
//Components and layouts
import LogBook from "./layout/logBook/logBook";
//Components and layouts
import TopMenu from "./component/topMenu";
import LogIn from "./layout/login/LogIn";
import HomePage from "./layout/homePage/HomePage";
import RequisitionsList from "./layout/requisitionsList/RequisitionList"
import RequisitionForm from "./layout/requisitionForm";
import RequisitionsItems from "./layout/requisitionsItemsList/requisitions-items";
import ProjectList from "./layout/projectsList/ProjectList";
import HelpSteps from "./layout/help/HelpSteps";
//
//

const App = () => {
	const [user, setUser] = useState(null)
	const [departements, setDepartements] = useState([])
	const [project, setProject] = useState(null)
	const [listeStm, setListeStm] = useState([])

	return <div className="App">
		<Router>
			<UserContext.Provider value={{user, setUser}}>
			<DepartementsContext.Provider value={{departements, setDepartements}}>
			<ProjectContext.Provider value={{project, setProject}}>
			<ListeStmContext.Provider value={{listeStm: listeStm, setListeStm: setListeStm}}>
			<div id='first'><Container>
			{user ? project ? (<>
				<TopMenu/>
				<Switch>
					<Route path="/requisitions/:id">
						<RequisitionForm/>
					</Route>
					<Route path="/requisitions-items">
						<RequisitionsItems/>
					</Route>
					<Route path="/requisitions">
						<RequisitionsList/>
					</Route>
					<Route path="/logbook">
						<LogBook/>
					</Route>
					<Route path="/aide">
						<HelpSteps/>
					</Route>
					<Route path="/">
						<HomePage/>
					</Route>
				</Switch>

			</> ): <ProjectList/> : ( <LogIn/> )
			}
			</Container>
			</div>
			</ListeStmContext.Provider>
			</ProjectContext.Provider>
			</DepartementsContext.Provider>
			</UserContext.Provider>
		</Router>
	</div>
}

export default App;
