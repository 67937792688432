import useHomePage from "./useHomePage"
//ui imports
import {Dimmer, Divider, Header, Loader} from "semantic-ui-react";
//
const HomePage = () => {
    const {user, dataIsLoaded} = useHomePage()
    console.log("homePage Render")
    //
    return (<>
        <Header>Bienvenue {user.firstName + " " + user.LastName}</Header>
        {
            !dataIsLoaded ? <>
                <Divider hidden/><Dimmer active inverted>
                <Loader size='large'>Chargement des données de configuration</Loader>
                </Dimmer>
            </> : <></>
        }
    </>)
}
export default HomePage