import React from "react";
import {Divider, Dimmer, Loader} from "semantic-ui-react"

const FetchLoader = () => (
    <>
        <Divider size={'large'} hidden/>
        <Dimmer active inverted>
            <Loader size='large'>Chargement</Loader>
        </Dimmer>
    </>
)
export default FetchLoader