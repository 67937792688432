import React, {useState} from 'react'
import {Modal, Button, Header, Icon} from 'semantic-ui-react'
import TableTemplate from './semantic-ui/table'


export default function RequisitionsFormOrdersItems (props) {
	const [open, setOpen] = React.useState(false)
	const {ordersItems} = props
	
	const oderedItems =()=> ordersItems.map( item => <pre>{JSON.stringify(item)}</pre> )

	return (
		<Modal
			basic
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			size='small'
			trigger={<Icon name='mail outline' />}
		>
			<Header icon>
				<Icon name='archive' />
				Items commandés
			</Header>
			<Modal.Content>
				<TableTemplate
					headerRow={['no cmd', "fournisseur", "qté cmd", "qté reçus et localisation"]}
					tableData={ordersItems}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button basic color='red' inverted onClick={() => setOpen(false)}>
					<Icon name='remove' /> No
				</Button>
				<Button color='green' inverted onClick={() => setOpen(false)}>
					<Icon name='checkmark' /> Yes
				</Button>
			</Modal.Actions>
		</Modal>
	)
}


