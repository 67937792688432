import {Table, Header, Icon, Button, Container, Grid, Image, Divider, Segment,Label, Message} from "semantic-ui-react";
import React, {useContext} from "react";
import {ProjectContext, UserContext} from "../../context/appContext";
import axios from "axios";
import {apiSrv} from "../../config/api-srv";
import clientLogo from '../../unibecLogo.png'

const ProjectList =()=> {
	const {user} = useContext(UserContext)
	const {setProject} = useContext(ProjectContext)
	const {userProjects} = user

	function getProject (projectRecordId) {
		
		axios.get(`${apiSrv}/projects/${projectRecordId}`, {headers : {"Authorization" : "Bearer " + user.token}})
			.then(project => setProject(project.data))
			.catch(err => alert("Une erreur c'Est produit veuillez réesseyer"))
		}

	return (<>
		<Container>
		<Grid textAlign="center" verticalAlign="middle">
			<Grid.Column style={{ maxWidth: 450 }} textalign={"center"}>
				<Image size='small' src={clientLogo} alt="logo"  centered/>{" "}
				{/*<Image size='small' src='http://www.fournierindustries.com/wp-content/uploads/2015/08/logo_inductriesfournier.png' alt="logo"  centered/>{" "}*/}
				<Divider hidden />
					<Segment>
						<Message success attached content={"Sélectionner un projet dans la liste"}>
							<Header>Bienvenue {user.firstName + " " + user.LastName}</Header>
							<p>Sélectionner un projet dans la liste pour commencer.</p>
						</Message>
						<Table basic='very' celled>
							<Table.Header>
							</Table.Header>
							<Table.Body>
								{userProjects.map(row => <Table.Row>
									<Table.Cell>
										<Header as='h4'>
											<Header.Content>
												{row.ref}
												<Header.Subheader inline>{row.name}</Header.Subheader>
											</Header.Content>
										</Header>
									</Table.Cell>
									<Table.Cell>
										<Button id={row.recordId} as='div' labelPosition='left' onClick={() => getProject(row.recordId) }>
											<Label as='a' basic>
												Afficher
											</Label>
											<Button icon>
												<Icon name='right arrow' />
											</Button>
										</Button>
									</Table.Cell>
								</Table.Row>)}
							</Table.Body>
						</Table>
					</Segment>
			</Grid.Column>
		</Grid>
		</Container>
	</>)
}
export default ProjectList
