import React, {useEffect, useState, useReducer} from 'react'
import {Button, Form, Icon, Modal} from 'semantic-ui-react'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import SemanticDatepicker from "react-semantic-ui-datepickers";

function modalReducer(state, action) {
	switch (action.type) {
		case 'OPEN_MODAL':
			return { ...state, formValues : resetForm(), open: true, dimmer: action.dimmer}
		case 'CLOSE_MODAL':
			return { ...state, open: false}
		case 'FIELDUPDATE':
			return { ...state, formValues : action.payload }
		case 'COMMIT' :
			return {...state, formValues : resetForm(), open: false }
		default:
			throw new Error()
	}
}

const resetForm = (dateRequis)=>{
	return {
		"postToServerGroup" : "new",
		"requisitionsItems::qty" : 0,
		"requisitionsItems::descriptionCustom": "",
		"requisitionsItems::unite": "unité",
		"requisitionsItems::dateRequis" : dateRequis ? new Date( dateRequis) : "",
		"requisitionsItems::note" : ""
	}
}
//

//
export default function RequisitionFormCustomForm (props) {
	const [state, dispatch] = useReducer(modalReducer, resetForm(props.dateRequis))
	const { open, formValues,dateRequis } = state
	const {onSubmitCallback} = props
	//

	function handleDateChange (event, data){
		setFormValues('requisitionsItems::dateRequis',data.value ? `${data.value.getMonth()+1}/${data.value.getDate()}/${data.value.getFullYear()}` : "" );
	};
	//
	function setFormValues (field, value){
		const payload =  { ...formValues, [field] :  value}
		dispatch({"type" : "FIELDUPDATE", "payload" : payload})
	};
	//
	function submit (){
		
		onSubmitCallback(formValues)
		dispatch({type : 'COMMIT'})
	};
	//
	return (
		<Modal
			dimmer = {"blurring"}
			open={open}
			// onClose={() =>  dispatch({type: 'CLOSE_MODAL'})}
			onOpen={() =>  dispatch({type: 'OPEN_MODAL'})}
			trigger={<Button color={'blue'} size={"small"} content='Ajouter un item custom' float={"right"}/>}
		>
			<Modal.Header>Formulaire de commandes spécialisé</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Input fluid
						name={'requisitionsItems::descriptionCustom'}
						label={'Description'} placeholder={'Description'}
						onBlur={(e) => setFormValues(e.target.name, e.target.value)}/>
					<Form.Group widths={'equal'}>
						<Form.Input fluid
												name={'requisitionsItems::qty'}
												label={'Qté'} placeholder={'qté.'}
												onBlur={(e) => setFormValues(e.target.name, e.target.value)}/>
						<Form.Input fluid
												name={'requisitionsItems::unite'}
												label={'Unité'}
												placeholder={'Unité'}
												onBlur={(e) => setFormValues(e.target.name, e.target.value)}/>
						<SemanticDatepicker fluid
												name={'requisitionsItems::dateRequis'}
												label={'Date requis'}
												value={dateRequis}
												datePickerOnly
												onChange={handleDateChange}/>
					</Form.Group>
					<Form.TextArea fluid
						name={'requisitionsItems::note'}
				 		label={'Note'}
				 		placeholder={'Autres informations'}
				 		onBlur={(e) => setFormValues(e.target.name, e.target.value)}
					/>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => dispatch({type : 'CLOSE_MODAL'}) } negative>
					Annuler <Icon name='cancel' />
				</Button>
				<Button  primary onClick={() => submit()}>
					Appliquer <Icon name='chevron right' />
				</Button>
			</Modal.Actions>
		</Modal>
	)
}