import React, {useEffect, useState, useReducer, useContext} from 'react'
import _ from 'lodash'
import {useHistory} from 'react-router-dom';
import axios from "axios";
//
//Import Context
import {ProjectContext, UserContext} from "../../context/appContext";
import {apiSrv} from "../../config/api-srv";
//
function stmReducer(sortState, action) {
    switch (action.type) {
        case 'FETCH' :
            return {
                ...sortState,
                data : action.payload,
                foundSet : action.payload,
                constrainOption : 'Ouvert seulement',
            }
        case 'LOADPAGE' :  {
            console.log(`LoadPage : ${action.firstRecord} to ${action.lastRecord}`);
            const newData = sortState.foundSet.slice(action.firstRecord, action.lastRecord);
            console.log(`newData : ${newData}`);
            return {...sortState,
                data : newData
            };
        }
        case 'CONSTRAIN' :
             {
               console.log(`ConstrainOption : ${action.constrainOption}`)
               if ( sortState.constrainOption === 'Ouvert seulement')  return {
                 ...sortState,
                 constrainOption : 'Afficher tout',
                 data: sortState.data.filter(item => item.statut === 'Ouvert')
             }
               return {...sortState, data : sortState.foundSet, constrainOption : 'Ouvert seulement',}
            }
        case 'CHANGE_SORT':
            if (sortState.column === action.column) {
                return {
                    ...sortState,
                    data: sortState.data.slice().reverse(),
                    direction:
                        sortState.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {
                column: action.column,
                data: _.sortBy(sortState.data, [action.column]),
                direction: 'ascending',
            }
        default:
            throw new Error()
    }
}

const useRequisitionsList = () => {
    //State
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState()
    const [sortState, setSortState] = useReducer(stmReducer, {
        "column": null,
        "data": [],
        "direction": null,
        "currentPage" : 1,
        "constrainOption" : "Ouvert seulement"
    })
    const {column, data, direction, constrainOption, foundSet, currentPage} = sortState
    //Context
    const {user} = useContext(UserContext)
    const {project} = useContext(ProjectContext)
    const rowsPerPage = 25
    let history = useHistory();
    //
    //On Loading
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${apiSrv}/users/${user.email}/projects/${project.PrimaryKey}/requisitions/`, {headers: {"Authorization": "Bearer " + user.token}})
            .then(
                (response) => {
                    setSortState({type: 'FETCH', payload: response.data})
                    setIsLoaded(true)
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                })
        // .catch(err => err)
    }, [])
    //
    function btnNewRequisition() {
        axios.post(`${apiSrv}/requisitions/`, {
            requerant: user.email,
            fkid_project: project.PrimaryKey
        }, {headers: {"Authorization": "Bearer " + user.token}})
            .then(res => {
                history.push("/requisitions/" + res.data.recordId)
            })
            .catch(err => console.log(err))
    }

    //
    function handleRowClick(recordId) {
        history.push("/requisitions/" + recordId);
    }

    //TODO compléter la navigation par page
    function goToTargetPage(targetPage) {
        const firstRecord = (targetPage - 1) * rowsPerPage;
        const lastRecord = firstRecord + rowsPerPage
        console.log(`targetPage : ${targetPage}`)
        setSortState({type : "LOADPAGE", firstRecord : firstRecord, lastRecord : lastRecord })
        alert(`selected Page : ${firstRecord}`)
    }

    return {error, isLoaded, column, data, direction, setSortState, user, project, constrainOption, btnNewRequisition, handleRowClick, handlePageBtnClick: goToTargetPage }
}

export default useRequisitionsList