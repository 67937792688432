import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Container, Divider,
    Form,
    Grid,
    Image, Message, Segment
} from "semantic-ui-react";
//
import {apiLogIn} from "../../config/api-srv";
import clientLogo from "../../unibecLogo.png"
import {UserContext, ListeStmContext} from "../../context/appContext";
//
const LogIn = ()=> {
    const [currentUserName, setCurrentUserName] = useState(null)
    const [currentPassword, setCurrentPassword] = useState(null)
    const [msg, setMsg] = useState(null)
    const {setUser} = useContext(UserContext)
   //
  useEffect(()=> {
    window.scrollTo(0, 0);
  },[])

   async function logMeIn (userName, pass) {
     await apiLogIn(userName, pass)
       .then(userObject => {
           
           userObject.status !== 200  ? setMsg(userObject.messages[0].message) : setUser(userObject.data)
       })
       .catch(err => {
           console.log("err : ", err)
       })
   }
   //
    return <Container>
        <Grid textAlign="center" verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }} textalign={"center"}>
                <Image size='small' src={clientLogo} alt="logo"  centered/>{" "}
                <Divider hidden />
                <Form size="large" onSubmit={() => logMeIn(currentUserName, currentPassword)}>

                    <Segment>
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="E-mail address"
                          name={"currentUserName"}
                          value={currentUserName ? currentUserName : ""}
                          onChange={(e) => setCurrentUserName(e.target.value)}
                        />
                        <Form.Input
                          fluid
                          icon="user"
                          iconPosition="left"
                          placeholder="Mot de passe"
                          name={"currentUserName"}
                          type={"password"}
                          value={currentPassword ? currentPassword : ""}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <Button type={"submit"} color="teal" fluid size="large" >
                            Log in
                        </Button>
                    </Segment>
                </Form>
                    <Message color={msg ? "negative" : ""}>
                        {msg ? (<><Message.Header>Erreur</Message.Header>
                          <p>{msg}</p></>) :"Bonjour, commencer par vous identifier."}
                </Message>
                </Grid.Column>
        </Grid>
    </Container>
}
//
export default LogIn