import React from 'react'
import {Button, Header, Image, Input, Modal, Table} from 'semantic-ui-react'

const EquipementsTab = () => <Table>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>
					Description STM
				</Table.HeaderCell>

				<Table.HeaderCell>
					Modèle ou #
				</Table.HeaderCell>

				<Table.HeaderCell>
					Qté
				</Table.HeaderCell>

				<Table.HeaderCell>
					H
				</Table.HeaderCell>

			</Table.Row>
			</Table.Header>

	<Table.Body>

		<Table.Row>
			<Table.Cell>
				Camionette
			</Table.Cell>

			<Table.Cell>
				99052
			</Table.Cell>

			<Table.Cell>
				5
			</Table.Cell>

			<Table.Cell>
				?
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				Camionette
			</Table.Cell>

			<Table.Cell>
				99052
			</Table.Cell>

			<Table.Cell>
				5
			</Table.Cell>

			<Table.Cell>
				?
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				Camionette
			</Table.Cell>

			<Table.Cell>
				99052
			</Table.Cell>

			<Table.Cell>
				5
			</Table.Cell>

			<Table.Cell>
				?
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				Camionette
			</Table.Cell>

			<Table.Cell>
				99052
			</Table.Cell>

			<Table.Cell>
				5
			</Table.Cell>

			<Table.Cell>
				?
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				Camionette
			</Table.Cell>

			<Table.Cell>
				99052
			</Table.Cell>

			<Table.Cell>
				5
			</Table.Cell>

			<Table.Cell>
				?
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				Camionette
			</Table.Cell>

			<Table.Cell>
				99052
			</Table.Cell>

			<Table.Cell>
				5
			</Table.Cell>

			<Table.Cell>
				?
			</Table.Cell>
		</Table.Row>

	</Table.Body>

	</Table>


export default EquipementsTab