import {Card, Feed, Header, Segment} from "semantic-ui-react";
import React from "react";


export default function RequisitionCard (props) {
	
	return(<>
		<Card fluid>
			<Card.Content>
				<Feed>
					<Feed.Event>
						{/*<Feed.Content summary={`Projet : `} content={`${props.project.ref}`}/>*/}
						<Feed.Content summary={`Réquisition : `} content={`${props.recordId}`}/>
						<Feed.Content summary={`Requérant: `} content={`${props.requerant}`}/>
						<Feed.Content summary={`Date de création : `} content={`${props.dateDeCreation}`}/>
					</Feed.Event>
				</Feed>
			</Card.Content>
		</Card></>)
}