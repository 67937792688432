import {Button,Icon, Input, Form, Table, TextArea} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import React from "react";
import RequisitionsFormOrdersItems from './requisitionsForm-ordersItems'


export default function RequistionItemRow (props) {
	const {row, listeStm, index, updateFieldInRequisitionItems, requisition, deleteRequisitionItem, ordersItems} = props
	console.log("row render : ", row)
	const pos = listeStm.findIndex(x => x.recordId === row["requisitionsItems::fkid_magasin"])
	const descriptionCalc = {
			description: pos === -1 ? row["requisitionsItems::descriptionCustom"] ? row["requisitionsItems::descriptionCustom"] : "" : listeStm[pos]["description_fr_cal"] + "\n" + listeStm[pos]["description_en_cal"],
			unite: pos === -1 ? row["requisitionsItems::unite"] ? row["requisitionsItems::unite"] : "unité" : listeStm[pos]["uniteDeVente::label"],
			prix: pos === -1 ? row["requisitionsItemsList::prix"] ? row["requisitionsItemsList::prix"] : 0 : listeStm[pos]["prixVendantUniteVendu"],
			dateRequis: row["requisitionsItems::dateRequis"] ? new Date(row["requisitionsItems::dateRequis"]) : requisition.dateRequis ? new Date(requisition.dateRequis) : null,
			groupeDeVente: pos === -1 ? "" : listeStm[pos]["groupeDeVente::label"],
			note: row["requisitionsItems::note"] ? row["requisitionsItems::note"] : "",
			noteHeight: row["requisitionsItems::note"] ? row["requisitionsItems::note"].split("\n").length : 1,
			flag_isNotEditableByClient : row["requisitionsItemsList::flag_isNotEditableByClient"] ? "PROUTE" : "PIF"
		}

		console.log("description calc : ", descriptionCalc.note)
	function handleDateChange  (event, data)
	{
		updateFieldInRequisitionItems(index, data.value ? `${data.value.getMonth() + 1}/${data.value.getDate()}/${data.value.getFullYear()}` : "", "requisitionsItems::dateRequis")
	}
	
	function numberToString(n){
		return n < 10 ? `0${n}` : `${n}`
	}
	// const readOnly = () => {
	// 	if (requisition.flag_traitementLockRecordChange === 0) return false
	// 	if (requisition.flag_traitementLockRecordChange === 1) return true
	// }
	//
	const renderDeleteOption = (flagNotEditable, index, ) => {
		
		if (flagNotEditable === 1) {
			
			
			return (<Button icon  basic>
						<RequisitionsFormOrdersItems key={row.PrimaryKey} ordersItems={ordersItems}/>
				</Button>)
		}
		
		if(requisition.flag_traitementLockRecordChange === 0){
			return (
				<Button icon color={"red"} basic
					onClick={() => deleteRequisitionItem(index)}>
				<Icon name='delete' />
			</Button> )
		}
	}
	//
	const renderQteInput = (row) => {
		
		if (requisition.flag_traitementLockRecordChange === 0 ){
			return (<Input transparent fluid
										 type={'number'}
										 placeholder='0'
										 value={row["requisitionsItems::qty"]}
										 onChange={(e) => updateFieldInRequisitionItems(index, parseInt(e.target.value, 10), "requisitionsItems::qty")}
		/>)}

		if (requisition.flag_traitementLockRecordChange === 1 ) {
			return row["requisitionsItems::qty"]
		}
	}
	const renderDateRequis = () => {
		return requisition.flag_traitementLockRecordChange === 0
			? ( <SemanticDatepicker fluid transparent datePickerOnly readOnly={false} value={descriptionCalc.dateRequis} onChange={handleDateChange}/>)
			: descriptionCalc.dateRequis === null
				? ""
				: `${descriptionCalc.dateRequis.getFullYear()}-${numberToString(descriptionCalc.dateRequis.getMonth()+1)}-${numberToString(descriptionCalc.dateRequis.getDate())}`
	}
	const renderTextArea = () => {
		return requisition.flag_traitementLockRecordChange === 0
			? (<Form><TextArea
				transparent={true}
				rows={descriptionCalc.noteHeight}
				value={descriptionCalc.note}
				onChange={e => updateFieldInRequisitionItems(index, e.target.value, "requisitionsItems::note")}
				/></Form>)
			: descriptionCalc.note
	}
	//
	if (row.postToServerGroup !== "delete") {
		return (<Table.Row key={index}>
			<Table.Cell>{row["requisitionsItems::fkid_magasin"] ? row["requisitionsItems::fkid_magasin"] : "Custom"}</Table.Cell>
			<Table.Cell>
				{descriptionCalc.description.split("\n").map((item, idx) => <span key={idx}>{item}<br/></span>)}
			</Table.Cell>
			<Table.Cell>
				{renderQteInput(row)}
			</Table.Cell>
			<Table.Cell>{descriptionCalc.unite}</Table.Cell>
			<Table.Cell>{descriptionCalc.groupeDeVente}</Table.Cell>
			<Table.Cell>{descriptionCalc.prix}</Table.Cell>
			<Table.Cell>
				{renderDateRequis(row)}
			</Table.Cell>
			<Table.Cell>{renderTextArea(row)}</Table.Cell>
			<Table.Cell>{
				renderDeleteOption(row["requisitionsItemsList::flag_isNotEditableByClient"], index, row.postToServerGroup)
			}</Table.Cell>
		</Table.Row>)

	}else
	{
		return null
	}
}