import {Button,Icon, Input, Form, Table, TextArea} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import React from "react";
import RequisitionsFormOrdersItems from './requisitionsForm-ordersItems'

export default function RequisitionItemRowV2 (props) {
    const {
        primaryKey,
        description,
        unite,
        prix,
        dateRequis,
        groupeDeVente,
        note,
        noteHeight,
        flag_isNotEditableByClient,
        flag_traitementLockRecordChange,
        fkid_magasin,
        index,
        qty,
    } = props

    //Callback function
    const {
        updateFieldInRequisitionItems,
        deleteRequisitionItem,
        ordersItems
    } = props
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Local Component////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderDeleteOption = () => {
        if (flag_isNotEditableByClient === 1) {
            return (
                <Button icon  basic>
                    <RequisitionsFormOrdersItems key={primaryKey} ordersItems={ordersItems}/>
                </Button>
            )
        }
        if(flag_traitementLockRecordChange === 0){
            return (
                <Button icon color={"red"} basic
                        onClick={() => deleteRequisitionItem(index)}>
                    <Icon name='delete' />
                </Button> )
        }
        //default
        return (<></>)
    }
    //
    const renderQteInput = () => {
        if (flag_traitementLockRecordChange === 0 ){
            return (<Input transparent fluid
                           type={'number'}
                           placeholder='0'
                           value={qty}
                           onChange={(e) => updateFieldInRequisitionItems(index, parseInt(e.target.value, 10), "qty")}
            />)}
        //
        if (flag_traitementLockRecordChange === 1 ) {
            return <>{qty}</>
        }

        return <>error</>
    }
    //
    const renderDateRequis = () => {
        return flag_traitementLockRecordChange === 0
            ? ( <SemanticDatepicker fluid transparent datePickerOnly readOnly={false} value={dateRequis} onChange={handleDateChange}/>)
            : dateRequis === null
                ? ""
                : `${dateRequis.getFullYear()}-${numberToString(dateRequis.getMonth()+1)}-${numberToString(dateRequis.getDate())}`
    }
    //
    const renderTextArea = () => {
        return flag_traitementLockRecordChange === 0
            ? (<Form><TextArea
                transparent={true}
                rows={noteHeight}
                defaultValue={note}
                onChange={e => updateFieldInRequisitionItems(index, e.target.value, "note")}
            /></Form>)
            : note
    }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// console.log("ordersItems : " ,ordersItems)
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (<Table.Row key={index}>
        <Table.Cell>{fkid_magasin}</Table.Cell>
        <Table.Cell>
            {description.split("\n").map((item, idx) => <span key={idx}>{item}<br/></span>)}
        </Table.Cell>
        <Table.Cell>
            {renderQteInput()}
        </Table.Cell>
        <Table.Cell>{unite}</Table.Cell>
        <Table.Cell>{groupeDeVente}</Table.Cell>
        <Table.Cell>{prix}</Table.Cell>
        <Table.Cell>
            {renderDateRequis()}
        </Table.Cell>
        <Table.Cell>
            {renderTextArea()}
        </Table.Cell>
        <Table.Cell>
            {renderDeleteOption()}
            {/*{<RenderDeleteOption flagNotEditable={flag_isNotEditableByClient} index={index}/>}*/}
        </Table.Cell>
    </Table.Row>)
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//event triggered fucntions//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleDateChange (){
        
    }
    function numberToString(n){
        return n < 10 ? `0${n}` : `${n}`
    }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}