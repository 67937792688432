import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {Dimmer, Divider, Grid, Loader, Table} from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import RequisitionFormCustomForm from "../component/requisitionForm-customForm";
//
import RequisitionFormHeader from "../component/requisitionForm-header";
import RequisitionCard from "../component/requisitionForm-headerCard";
import RequistionItemRow from "../component/requisitionForm-items";
import ErrorMsg from "../component/errorMsg";

import RequisitionFormSubMenu from "./requisitionForm/RequisitionFormSubMenu";


import {apiSrv} from "../config/api-srv";
import {UserContext, ProjectContext, ListeStmContext} from "../context/appContext";
import RequisitionFormSearchToolsMenuItems from "./requisitionForm/RequisitionFormSearchToolsMenuItems";



//TODO capturer les champs modifié seulement pour le patch.

//TODO mettre les requisitions custom dans un tableau différent

export default function RequisitionForm () {
	const [error, setError] = useState(null)
	// donnée reçus par le serveur
	const [requisition, setRequisition] = useState()
	const [requisitionItems,setRequisitionItems] = useState()
	const [patchCount , setPatchCount] = useState(0)
	const [uploading , setUploading] = useState(false)
	const [uploadBtnColor , setUploadBtnColor] = useState('basic')

	const {user} = useContext(UserContext)
	const {project} = useContext(ProjectContext)
	const {listeStm} = useContext(ListeStmContext)
	//
	const readOnly = () => requisition.flag_traitementLockRecordChange === 1
	const { id } = useParams();

	useEffect(() => {
		const {token} = user
		window.scrollTo(0, 0);
		axios.get(`${apiSrv}/requisitions/${id}`,{headers : {"Authorization" : "Bearer " + token}}).then((res) => {
			setUploadBtnColor('basic')
			

			setRequisition({...res.data[0].fieldData, recordId : res.data[0].recordId, ordersItems : res.data.cmdItems })
			setRequisitionItems(res.data[0].portalData.requisitionsItems)

			window.scrollTo(0, 0)
		},
			(error) => {
				setError(error);
			}).catch(err => err);
	}, [id, patchCount])
	//
	//
	useEffect(()=>{
		
	},[requisition])
	//
	//
	useEffect(()=>{
		console.log("items : ", requisitionItems)
	},[requisitionItems])
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// async function setRequisitionItemsAndOrdersItems (res){
	// 	setRequisitionItems(res.data[0].portalData.requisitionsItemsList)
	// 	setRequisition({...res.data[0].fieldData, recordId : res.data[0].recordId})
	// }


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	function updateFieldInRequisition (newValue, targetKey){
		setUploadBtnColor('green')
		const newRequisition = {...requisition, [targetKey] : newValue}
		setRequisition(newRequisition)
	}
	//
	function updateFieldInRequisitionItems (row, newValue, targetKey) {
		setUploadBtnColor('green')
		let newRequisitionItems = [...requisitionItems]

		newRequisitionItems[row] =  newRequisitionItems[row].hasOwnProperty('postToServerGroup') ?
			{...requisitionItems[row], [targetKey]: newValue} : {...requisitionItems[row], [targetKey]: newValue, postToServerGroup :"mod"}

		setRequisitionItems([...newRequisitionItems])
	}
	//
	function deleteRequisitionItem (row) {
		let newRequisitionItems = [...requisitionItems]

		if (requisitionItems[row].postToServerGroup === "new") newRequisitionItems.splice(row, 1)
		else {
			setUploadBtnColor('green')
			newRequisitionItems[row].postToServerGroup = "delete"
		}
		setRequisitionItems([...newRequisitionItems])
	}
	//
	// function updateRequisitionItemsFromStmForm (newArray) {
	// 	setUploadBtnColor('green')
	// 	setRequisitionItems([...newArray])
	// }
	//
	function addItemFromSearchTool (stmId){
		// const newArray = [{"requisitionsItemsList::fkid_magasin" : stmId, postToServerGroup :"new"}, ...requisitionItems]
		const newItem =  {"requisitionsItems::fkid_magasin" : stmId, "requisitionsItems::qty" : "", "requisitionsItems::note" : "", postToServerGroup :"new"};
		setRequisitionItems([ newItem, ...requisitionItems])
	}
	//
	function submitNewRequisitionCustom (requisitionCustom){
		
		let newArray = [...requisitionItems, requisitionCustom]
		setUploadBtnColor('green')
		setRequisitionItems([...newArray])
	}
	//
	function commitOrder(){
		setUploading(true)
		setRequisition({...requisition, flag_traitementLockRecordChange : 1})
		const payload = {...requisition, flag_traitementLockRecordChange : 1}
		payload.items = [...requisitionItems].filter(item => !!item.postToServerGroup).map(item => {
			// delete item.postToServerGroup
			return item
		})
		
		
		axios.patch(`${apiSrv}/requisitions/${id}`,{...payload}, {headers : {"Authorization" : "Bearer " + user.token}})
			.then(() => {
				
				setPatchCount(patchCount + 1)
				setUploading(false)
			})
			.catch(err => err)
	}
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//RENDER
	if (error) return (<ErrorMsg/>)
	//
	if(!requisitionItems)return (<>
		<Divider hidden/>
		<Dimmer active inverted><Loader size='large'>Chargement des données</Loader></Dimmer>
	</>)
	//
	if(requisitionItems)return (<>
		<Grid columns={2}>
			<Grid.Column>
				<RequisitionFormHeader
					recordId={requisition.recordId}
					titre={requisition.titre}
					activities={project.activities}
					project={project.ref}
					chantier={requisition.chantier}
					noBp={requisition.noBp}
					dateRequis={requisition.dateRequis}
					readOnly={readOnly()}
					updateField={updateFieldInRequisition}
				/>
			</Grid.Column>
			<Grid.Column>
			<Grid.Row>
					<RequisitionCard
						project={project}
						recordId={requisition.ref_requisition_calc}
						dateDeCreation={requisition.dateDeCreation}
						requerant={requisition.requerant}
						totalDeLaRequisition={requisition.totalDeLarequisition}
					/>
			</Grid.Row>
			<Grid.Row>
			</Grid.Row>
			</Grid.Column>
		</Grid>

		{readOnly() ?
			<RequisitionFormSubMenu readOnly={readOnly()} uploadBtnColor={uploadBtnColor}/> :
			<RequisitionFormSubMenu
				commitOrder={commitOrder}
				uploading={uploading}
				uploadBtnColor={uploadBtnColor}
				readOnly={readOnly()}
				leftSideMenuItems={
					<RequisitionFormSearchToolsMenuItems
						handleSelection={addItemFromSearchTool}
						noResultOptionBtn={
							<RequisitionFormCustomForm
								projet={requisition.projet}
								chantier={requisition.chantier}
								noBp={requisition.noBp}
								dateRequis={requisition.dateRequis}
								onSubmitCallback={submitNewRequisitionCustom}
						/>}
				/>}
		/>}
		<Table celled striped size='small' compact={"very"} attached>
			<Table.Header><Table.Row><Table.HeaderCell>Ref. STM</Table.HeaderCell><Table.HeaderCell>Description</Table.HeaderCell><Table.HeaderCell>Qté.</Table.HeaderCell><Table.HeaderCell>Unité</Table.HeaderCell><Table.HeaderCell>Vente</Table.HeaderCell><Table.HeaderCell>Prix</Table.HeaderCell><Table.HeaderCell>Date Requise</Table.HeaderCell><Table.HeaderCell>Note</Table.HeaderCell><Table.HeaderCell>Statut</Table.HeaderCell></Table.Row></Table.Header>

			<Table.Body>
				{requisitionItems.map((row, index,) => {
						return (
							<RequistionItemRow
								ordersItems={requisition.ordersItems.filter(item => item.fkid_requisitionsItems === row["requisitionsItemsList::PrimaryKey"])}
								requisition={requisition}
								listeStm={listeStm}
								row={row}
								index={index}
								updateFieldInRequisitionItems={updateFieldInRequisitionItems}
								deleteRequisitionItem={deleteRequisitionItem}
							/>)
					}
				)}
			</Table.Body>
		</Table>
	</>)
}