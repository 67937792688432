import React, {useState} from "react";
import {Header, Step} from "semantic-ui-react";
import './helpSteps.css'

const helpStepsArray = [
    {stepName: "validation",iconName: "edit", title: "Valider", description: "Demander des prix"},
    {stepName: "traitement",iconName: "cart", title: "Traiter", description: "Commander à un fournisseur"},
    {stepName: "reception", iconName: "box",  title: "Recevoir",description: "Recevoir une commande"},
]
//
const s3Videos = {
    validation  : 'https://unibec-webapp.s3.ca-central-1.amazonaws.com/tuto-admin/tuto1.mp4',
    traitement  : 'https://unibec-webapp.s3.ca-central-1.amazonaws.com/tuto-admin/tuto2.mp4',
    reception   : 'https://unibec-webapp.s3.ca-central-1.amazonaws.com/tuto-admin/tuto3.mp4',
}
//
const HelpStepContent = ({title = "", description = ""}) => {
    return  (<>
        <Step.Title>{title}</Step.Title>
        <Step.Description>{description}</Step.Description>
    </>)
}
//
export default function HelpSteps ()  {
    const [currentStep , setCurrentStep] = useState('validation')
    const evaluateIsActive = (stepName) => currentStep === stepName
    const handelOnStepClick = (event, data) =>  setCurrentStep(data.stepName)
    //
    return <>
        <Header content={`Bienvenue dans l'aide`} />
        <Step.Group fluid>{helpStepsArray.map( step => (

            <Step
                active={evaluateIsActive(step.stepName)}
                icon={step.iconName}
                content={HelpStepContent(step)}
                stepName={step.stepName}
                onClick={handelOnStepClick}
            />

        ))}</Step.Group>

        <video src={s3Videos[currentStep]} controls/>
    </>
}