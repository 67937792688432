import useRequisitionsList from './useRequisitionsList'
//UI FrameWork Imports
import {Button, Grid, Input, Table} from "semantic-ui-react";
// UI Local Components
import ProjectHeader from "../../component/projectHeader"
import PaginationTableFooter from "../../component/tables/paginationTableFooter"
import FetchLoader from "../../component/loaders/fectchLoader"
import "./requisitionsList.css"
import React from "react";
//
const RequisitionsList = () => {
    const {error, isLoaded, column, data, direction, setSortState, project, constrainOption, btnNewRequisition, handleRowClick, handlePageBtnClick } = useRequisitionsList()

    if (error){
        return <div>Erreur : {error.message}</div>;
    }

    if(!isLoaded){
        return <FetchLoader/>
    }

    return <>
        <ProjectHeader project={project} btnNewRequisition={btnNewRequisition}/>
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column>
                <Button content={constrainOption} onClick={()=> setSortState({type : 'CONSTRAIN', constrainOption : "open" })}/>
            </Grid.Column>
            <Grid.Row columns={2}>
                <Button floated='right' primary content='Ajouter une réquisition' onClick={btnNewRequisition}/>
            </Grid.Row>
        </Grid.Row>
    </Grid>
        {/*<Button content={constrainOption} onClick={()=> setSortState({type : 'CONSTRAIN', constrainOption : "open" })}/>*/}
        {/*<Input inline/>*/}
        <Table size={'small'} compact={'very'} sortable celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                        sorted={column === 'ref_requisition_calc' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'ref_requisition_calc' })}
                        content={'Réf.'}
                    />
                    <Table.HeaderCell
                        sorted={column === 'titre' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'titre' })}
                        content={'Titre.'}
                    />
                    <Table.HeaderCell
                        sorted={column === 'projet' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'projet' })}
                        content={'Projet'}
                    />
                    <Table.HeaderCell
                        sorted={column === 'chantier' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'chantier' })}
                        content={'Lieu de livraison'}
                    />
                    <Table.HeaderCell
                        sorted={column === 'noBp' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'noBp' })}
                        content={'Activité'}
                    />
                    <Table.HeaderCell
                        sorted={column === 'dateDeCreation' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'dateDeCreation' })}
                        content={'Date de création'}
                    />
                    <Table.HeaderCell
                        sorted={column === 'totalDeLarequisition' ? direction : null}
                        onClick={() => setSortState({ type: 'CHANGE_SORT', column: 'totalDeLarequisition' })}
                        content={'Statut'}
                    />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <TableRows rows={data} handleClick={handleRowClick}/>
            </Table.Body>
            <PaginationTableFooter colCount={6} count={6} isActive={2} handleClick={handlePageBtnClick}/>
        </Table>
    </>
}

const TableRows = (props) => {
    const {rows, handleClick} = props
    return rows.map( (row, index) => {
            const isClosed = () => row.statut !== 'Ouvert'
            return (
                <Table.Row
                    id={row.recordId}
                    arrIndex={index}
                    onClick={() => handleClick(row.recordId)}
                    positive={isClosed()}
                >
                    <Table.Cell>{row.ref_requisition_calc}</Table.Cell>
                    <Table.Cell>{row.titre}</Table.Cell>
                    <Table.Cell>{row.projet}</Table.Cell>
                    <Table.Cell>{row.chantier}</Table.Cell>
                    <Table.Cell>{row.noBpName}</Table.Cell>
                    <Table.Cell>{row.dateDeCreation}</Table.Cell>
                    <Table.Cell>{row.statut}</Table.Cell>
                </Table.Row>
            )
        }
    )
}

export default RequisitionsList