import React, {useContext, useEffect, useState} from "react"
import {useHistory} from 'react-router-dom';
import {Button, Dimmer, Divider, Loader, Header, Table} from "semantic-ui-react"
import axios from "axios"
//
import {ListeStmContext, ProjectContext, UserContext} from "../../context/appContext";
import {apiSrv} from '../../config/api-srv'
import RequisitionItemRowV2 from '../../component/requisitionItemRow'
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function RequisitionsItems ()  {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [data, setData] = useState()
    const [setUploadBtnColor] = useState('basic')
//contexte
    const {user} = useContext(UserContext)
    const {project} = useContext(ProjectContext)
    const {listeStm} =useContext(ListeStmContext)
//config
    const url = `${apiSrv}/users/${user.email}/projects/${project.PrimaryKey}/requisitions/items`
    const header = {headers : {"Authorization" : "Bearer " + user.token}}
    let history = useHistory();
//
    useEffect(() => {
        window.scrollTo(0, 0);
        //
        axios.get( url , header)
            .then(
                (response) => {
                    setData(response.data)
                    setIsLoaded(true)
                },
                (error) => {
                    setError(error)
                    setIsLoaded(true)
                })
        // .catch(err => err)
    }, [])


    if (error){
        return <div>Erreur : {error.message}</div>
    }else if (!isLoaded){
        return (<>
            <Divider size={'large'} hidden/>
            <Dimmer active inverted>
                <Loader size='large'>Chargement</Loader>
            </Dimmer>
        </>)
    }else {
        return (<>


            <Header as={'h2'}/>
            <Button.Group bassic>
                <Button content={"Ajouter"} icon={"plus"} onClick={() => btnNewRequisition()}/>
                {/*<Button content={"Rechercher"} icon={"search"}/>*/}
                {/*{uploadBtnColor === "green" ?*/}
                {/*    <Button*/}
                {/*        content={"Sauvegarder"}*/}
                {/*        color={uploadBtnColor}*/}
                {/*        loading={uploading}*/}
                {/*        onClick={() => commitChange()}*/}
                {/*    />*/}
                {/*    : <></>*/}
                {/*}*/}
            </Button.Group>

                <Table celled striped size='small' compact={"very"}>
                    <Table.Header><Table.Row>
                        <Table.HeaderCell>Ref. STM</Table.HeaderCell><Table.HeaderCell>Description</Table.HeaderCell><Table.HeaderCell>Qté.</Table.HeaderCell><Table.HeaderCell>Unité</Table.HeaderCell><Table.HeaderCell>Vente</Table.HeaderCell><Table.HeaderCell>Prix</Table.HeaderCell><Table.HeaderCell>Date Requise</Table.HeaderCell><Table.HeaderCell>Note</Table.HeaderCell><Table.HeaderCell>Statut</Table.HeaderCell>
                    </Table.Row></Table.Header>
                    <Table.Body>
                        {data.requisitionsItems.map((row, index,) => {
                            const pos = listeStm.findIndex(x => x.recordId === row.fkid_magasin)
                            if (row.postToServerGroup !== "delete"){
                                return (
                                    <RequisitionItemRowV2
                                        primaryKey = {row.PrimaryKey}
                                        description={pos === -1 ? row.descriptionCustom ? row.descriptionCustom : "" : listeStm[pos].description_fr_cal + "\n" + listeStm[pos].description_en_cal}
                                        unite ={pos === -1 ? row.unite ? row.unite : "unité" : listeStm[pos].label}
                                        prix={pos === -1 ? row.prix ? row.prix : 0 : listeStm[pos]["description_en_cal"]}
                                        dateRequis={row.dateRequis ? new Date(row.dateRequis) : row.requisitions.dateRequis ? new Date(row.requisitions.dateRequis) : null}
                                        groupeDeVente={pos === -1 ? "" : listeStm[pos]["groupeDeVente::label"]}
                                        note={row.note ? row.note : ""}
                                        noteHeight={row.note ? row.note.split("\n").length : 1}
                                        flag_isNotEditableByClient={row.flag_isNotEditableByClient ? "PROUTE" : "PIF"}
                                        fkid_magasin={row.fkid_magasin ? row.fkid_magasin : "Custom"}
                                        ordersItems={data.cmdItems.filter(item => item.fkid_requisitionsItems === row.PrimaryKey)}
                                        index={index}
                                        qty={row.qty}
                                        flag_traitementLockRecordChange = {1}  //{row.requisitions.flag_traitementLockRecordChange}
                                        updateFieldInRequisitionItems={updateFieldInRequisitionItems}
                                        deleteRequisitionItem={deleteRequisitionItem}
                                        postToServerGroup={row.postToServerGroup}
                                    />)
                            }else {
                                return (<></>)
                            }
                        })}
                    </Table.Body>
                </Table>
        </> )
    }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function updateFieldInRequisitionItems (rowIndex, newValue, targetKey){
        let newRequisitionsItems = [...data.requisitionsItems]
        newRequisitionsItems[rowIndex] = newRequisitionsItems[rowIndex].hasOwnProperty('postToServerGroup') ?
            {...data.requisitionsItems[rowIndex], [targetKey]:newValue} : {...data.requisitionsItems[rowIndex], [targetKey] : newValue, postToServerGroup :"mod"}
        updateDataRequisitionsItems(newRequisitionsItems)
    }
    function deleteRequisitionItem (rowIndex){
        let newRequisitionsItems = [...data.requisitionsItems]
        data.requisitionsItems.postToServerGroup === "new" ?
            newRequisitionsItems.splice(rowIndex, 1) :
            newRequisitionsItems[rowIndex].postToServerGroup = "delete"
        updateDataRequisitionsItems(newRequisitionsItems)
    }
    function updateDataRequisitionsItems (newRequisitionsItems){
        setData({...data, requisitionsItems : [...newRequisitionsItems]})
        setUploadBtnColor('green')
    }
    // function commitChange (){
    //     setUploading(true)
    //     const payload = [...data.requisitionsItemsList].filter(item => !!item.postToServerGroup).map(item =>{
    //         return item
    //     })
    //     setUploading(false)
    //     setUploadBtnColor("")
    // }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function btnNewRequisition (){
        axios.post(`${apiSrv}/requisitions/`, { requerant : user.email, fkid_project : project.PrimaryKey},{headers : {"Authorization" : "Bearer " + user.token}})
            .then(res => {
                history.push("/requisitions/" + res.data.recordId )
            })
            .catch(err => console.log(err))
    }
}

export default RequisitionsItems