import {
	Button,
	Card,
	Form,
	Grid,
	Label,
	Menu,
	Tab,
	Table
} from 'semantic-ui-react'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import TimeInputByActivity from "../../component/logbook-timeinput-byactivity";
import EquipementsTab from "../../component/logbook-equipements";


const HeaderCard = ()=>  <Card>
	<Card.Content>
		<Card.Header>Rapport Journalier no. : XX01</Card.Header>
		<Card.Meta>Projet : X </Card.Meta>
		<Card.Meta>Chargé de projet : Olivier Niquet </Card.Meta>
		<Card.Meta>Produit par :</Card.Meta>
		<Card.Description>
			Matthew Harris
		</Card.Description>
	</Card.Content>
</Card>

const StaffTable = () => <Table>
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell>
				No
			</Table.HeaderCell>
			<Table.HeaderCell>
				Nom
			</Table.HeaderCell>
			<Table.HeaderCell>
				HTS
			</Table.HeaderCell>
			<Table.HeaderCell>
				HT 1/2
			</Table.HeaderCell>
			<Table.HeaderCell>
				HTD
			</Table.HeaderCell>
			<Table.HeaderCell>
				Voy
			</Table.HeaderCell>
			<Table.HeaderCell>
				Repas
			</Table.HeaderCell>
			<Table.HeaderCell>
				Pension
			</Table.HeaderCell>
			<Table.HeaderCell>
				Statut
			</Table.HeaderCell>
		</Table.Row>
	</Table.Header>

	<Table.Body>
		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Jo di Magio
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				<TimeInputByActivity/>
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Pierre Jean-Jacques
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Johanne Leduc
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Olivia
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Joselito Michaud
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Jo di Magio
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Frank Zappa
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Joe Dalton
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				Mario el Gros
			</Table.Cell>
			<Table.Cell>
				2
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				6
			</Table.Cell>
			<Table.Cell>
				4
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				1
			</Table.Cell>
			<Table.Cell>
				pret
			</Table.Cell>
		</Table.Row>
	</Table.Body>
</Table>


const ActiviteDeRapport = () => <Table>
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell>
				No BP
			</Table.HeaderCell>

			<Table.HeaderCell>
				Nom d'activité
			</Table.HeaderCell>

			<Table.HeaderCell>
				Total des heures
			</Table.HeaderCell>
		</Table.Row>
	</Table.Header>

	<Table.Body>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				activité 01
			</Table.Cell>
			<Table.Cell>
				<Button.Group>
					<Button icon={"team"}/>
					<Button icon={"truck"}/>
					<Button icon={"truck"}/>
				</Button.Group>
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				activité 02
			</Table.Cell>
			<Table.Cell>
				12
			</Table.Cell>
		</Table.Row>

		<Table.Row>
			<Table.Cell>
				123456
			</Table.Cell>
			<Table.Cell>
				activité 03
			</Table.Cell>
			<Table.Cell>
				35
			</Table.Cell>
		</Table.Row>

	</Table.Body>
</Table>

	const panes = [
		{
			menuItem: { key: 'activity', icon: 'target', content: 'Bons de productions' },
			render: () => <Tab.Pane>
				<ActiviteDeRapport/>
				Un clic affiche un formulaire de saisi des heures par employé </Tab.Pane>,
		},
		{
			menuItem: { key: 'users', icon: 'users', content: 'Équipe' },
			render: () => <Tab.Pane>
				<Button.Group float={'right'}>
					<Button content={'Ajouter du projet'}/>
					<Button content={'Ajouter custom'}/>
				</Button.Group>
				<StaffTable/>
				Cliquer sur le nom affiche un formulaire de saisi des heures par activité
			</Tab.Pane>,
		},
		{
			menuItem: { key: 'equipement', icon: 'truck', content: 'Équipement' },
			render: () => <Tab.Pane>
				<EquipementsTab/>
				Saisi des heures par activité ?<br/>
				Est égale à la liste STM
			</Tab.Pane>,
		},
		{
			menuItem: { key: 'soustraitants', icon: 'briefcase', content: 'Sous-Traitants' },
			render: () => <Tab.Pane>Comme équipement</Tab.Pane>,
		},
		{
			menuItem: { key: 'materiaux', icon: 'shopping cart', content: 'Matériaux' },
			render: () => <Tab.Pane>Comme équipement</Tab.Pane>,
		},
		{
			menuItem: (
				<Menu.Item key='messages'>
					Messages<Label>15</Label>
				</Menu.Item>
			),
			render: () => <Tab.Pane>Tab 2 Content</Tab.Pane>,
		},
	]






const LogBook = () =>
	<>
		<Grid>
			<Grid.Column width={5}>
				<HeaderCard/>
			</Grid.Column>
			<Grid.Column width={11}>
				<Form>
					<Form.Input label={'Jour/ Nuit'}/>
					<Form.Input label={'Date'}/>
					<Button floated={'right'} color={'primary'} content={'Soumettre'}/>
				</Form>
			</Grid.Column>
		</Grid>

		<Tab panes={panes} />
	</>
export default  LogBook