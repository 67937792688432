import React from "react";
import {Button, Menu} from "semantic-ui-react";

export default function RequisitionFormSubMenu ({commitOrder, leftSideMenuItems, handleSelection, uploading, uploadBtnColor, readOnly, noResultOptionBtn}){

    const SendButton = () => {
        //
        return (<Button
            labelPosition={"right"}
            size={"small"}
            content={"Transférer à l'entrepôt"}
            icon={'right arrow'}
            onClick={() => commitOrder()}
            color={uploadBtnColor}
            loading={uploading}
        />)
    }
    //
    return  (
        <>
            <Menu attached='top'>
                {readOnly?
                        <Menu.Item header content={"Réquisition vérouillé"}/>
                        :[
                            leftSideMenuItems,
                            <Menu.Menu position={"right"}> <Menu.Item content={<SendButton/>}/> </Menu.Menu>
                        ]
                }
            </Menu>
        </>
    )
//
}