import React from 'react'
import { Table } from 'semantic-ui-react'

// const tableData = [
// 	{ name: undefined, status: undefined, notes: undefined },
// 	{ name: 'Jimmy', status: 'Requires Action', notes: undefined },
// 	{ name: 'Jamie', status: undefined, notes: 'Hostile' },
// 	{ name: 'Jill', status: undefined, notes: undefined },
// ]

// const headerRow = ['Name', 'Status', 'Notes']

const renderBodyRow = ({ poRef, fournisseursNom , qty, qty_received, receptionItemsArr }, i) => ({
	cells: [
		poRef,
		fournisseursNom || "inconue",
		qty || 0 ,
		receptionItemsArr.length > 0
			? renderReceptionItems(receptionItemsArr)
			: 'aucun message',
	],
})

const renderReceptionItems = (arr) => {
	let res = "";
	arr.forEach(item => res = `${res} ${item.qte} ${item.location} \n`)
	
	return res
}

const TableTemplate = (props) => (
	<Table
		celled
		headerRow={props.headerRow}
		renderBodyRow={renderBodyRow}
		tableData={props.tableData}
	/>
)

export default TableTemplate