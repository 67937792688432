import React from "react";
import {Button, Grid, Header} from "semantic-ui-react";


const  ProjectHeader = (props) => <>
	<Grid>
		<Grid.Row columns={2}>
			<Grid.Column>
				<Header content={`Projet ${props.project.ref}`}/>
			</Grid.Column>
			<Grid.Column>
				{/*<Button floated='right' primary content='Ajouter une réquisition' onClick={() => props.btnNewRequisition()}/>*/}
			</Grid.Column>
		</Grid.Row>
		{/*<Grid.Row columns={2}>*/}
		{/*	<Button floated='right' primary content='Ajouter une réquisition' onClick={() => props.btnNewRequisition()}/>*/}
		{/*</Grid.Row>*/}
	</Grid>
</>
export default ProjectHeader