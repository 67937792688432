import {Card, Feed, Form, Grid} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import React from "react";
//Classes
import SelectOption from "../class/selectOption";
//
const RequisitionFormHeader = (props) => {
	const {readOnly} = props
	const dateRequis =  props.dateRequis ? new Date(props.dateRequis) : null
	const activities = props.activities.map((activity) => new SelectOption(activity.PrimaryKey, `${activity.ref} ${activity.description}`, activity.PrimaryKey))
	const deliveryLocations = [{key:"chantier", text: "chantier", value: "chantier"}, {key:"entrepot", text : "entrepôt", value: "entrepot"}, {key:"pickUp", text : "pick Up", value: "pickUp"}]

	function handleFieldChange (event){
		props.updateField(event.target.value, event.target.name)
	}

	function handleDropDownChange (event, data){
		props.updateField(data.value, data.name)
	}

	function handleDateChange  (event, data) {
		props.updateField(data.value ? `${data.value.getMonth() + 1}/${data.value.getDate()}/${data.value.getFullYear()}` : "", 'dateRequis')
	}

	function getDeliveryLocationText (){
		let res = "" ;
		const resArr = deliveryLocations.filter( location => {
			if (location.key === props.chantier) {
				
				res = location.text
				return location.text
			}
		})
		return res
	}

	function getActivityText (){
		let res = "" ;
		const resArr = activities.filter( activitie => {
			if (activitie.key === props.noBp) {
				
				res = activitie.text
				return activitie.text
			}
		})
		return res
	}

	const renderSelect = () => {
		return readOnly === true
			?	(<><Form.Field inline fluid>
				<label id={"datePickerLabel"}>Date requis</label>
				<SemanticDatepicker fluid datePickerOnly
														readOnly={readOnly}
														clearable={!readOnly}
														value={dateRequis}
														onChange={handleDateChange}/>
				</Form.Field>
				<Form.Input fluid
									 readOnly={readOnly}
									 name={'chantier'}
									 label='Lieu de livraison :'
									 value={getDeliveryLocationText()}/>
				<Form.Input fluid
								 readOnly={readOnly}
								 name={"noBp"}
								 label='Activités'
								 value={getActivityText()}/></>)

			: (<>
				<Form.Field inline fluid>
				<label id={"datePickerLabel"}>Date requis</label>
				<SemanticDatepicker fluid datePickerOnly
															readOnly={readOnly}
															value={dateRequis}
															onChange={handleDateChange}/>
				</Form.Field>
				<Form.Select fluid
										 as={Form.Field}
										 disabled={readOnly}
										 name={'chantier'}
										 options={deliveryLocations}
										 label='Lieu de livraison :'
										 defaultValue={"chantier"}
										 onChange={handleDropDownChange}/>
				<Form.Select fluid
										 disabled={readOnly}
										 name={"noBp"}
										 options={activities}
										 label='Activités'
										 defaultValue={props.noBp}
										 onChange={handleDropDownChange}/></>)
	}

	return(<Form>
						<Form.Input fluid
												readOnly={readOnly}
												name={"titre"}
												label={"Titre ou description"}
												placeholder={"donner un titre ou une description de votre réquisition"}
												value={props.titre}
												onChange={handleFieldChange}/>
						<Form.Group fluid widths={'equal'}>
							{renderSelect()}
						</Form.Group>
	</Form>)
}

export default RequisitionFormHeader