import React from 'react'
import {Button, Header, Image, Input, Modal, Table} from 'semantic-ui-react'

function TimeInputByActivity() {
	const [open, setOpen] = React.useState(false)

	return (
		<Modal
			dimmer = {"blurring"}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={<Button>fdt staff par activite</Button>}
		>
			<Modal.Header>Feuille de temps de Joe Di Magio</Modal.Header>
			<Modal.Content>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								No d'activité
							</Table.HeaderCell>

							<Table.HeaderCell>
								Nom d'activité
							</Table.HeaderCell>

							<Table.HeaderCell>
								HTS
							</Table.HeaderCell>
							<Table.HeaderCell>
								HT 1/2
							</Table.HeaderCell>
							<Table.HeaderCell>
								HTD
							</Table.HeaderCell>
							<Table.HeaderCell>
								Voy
							</Table.HeaderCell>
							<Table.HeaderCell>
								Repas
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>

						<Table.Row>
							<Table.Cell>
								123456
							</Table.Cell>
							<Table.Cell>
								activité 01
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell>
								123456
							</Table.Cell>
							<Table.Cell>
								activité 02
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell>
								123456
							</Table.Cell>
							<Table.Cell>
								activité 03
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
							<Table.Cell>
								<Input fluid defaultValue={0}/>
							</Table.Cell>
						</Table.Row>

					</Table.Body>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button color='black' onClick={() => setOpen(false)}>
					Annuler
				</Button>
				<Button
					content="Confirmer"
					labelPosition='right'
					icon='checkmark'
					onClick={() => setOpen(false)}
					positive
				/>
			</Modal.Actions>
		</Modal>
	)
}

export default TimeInputByActivity