import {useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Button,
    Container,
    Grid,
    Icon,
    Menu,
    Image,
} from "semantic-ui-react";

import {apiLogOut} from "../config/api-srv";
import {UserContext, ProjectContext} from "../context/appContext";
import clientLogo from "../unibecLogo.png";

const AppMenuHeader = () => <Menu.Item ><Image floated='left' size='small' src={clientLogo} /></Menu.Item>
const AppMenuItems = () => {
    let history = useHistory();
    let [activeItem, setActiveItem] = useState();
    const {user, setUser} = useContext(UserContext)
    const {setProject} = useContext(ProjectContext)

    function handleClick (activeItem, target) {
        setActiveItem(activeItem);
        history.push(target)
    }

    function handleExit () {
      setUser(apiLogOut())
      setProject(undefined)
      history.push("/")
    }

    function handelClickOnUserNenu (){
      setProject(undefined)
      history.push("")
    }

    return (
        <>
            <Menu.Item
                name='requisitions'
                active= {activeItem === 'requisitions'}
                onClick={() => handleClick("requisitions", "/requisitions")}
            >
                Réquisitions
            </Menu.Item>
          {/*<Menu.Item*/}
          {/*  name='journal'*/}
          {/*  active= {activeItem === 'journal'}*/}
          {/*  onClick={() => handleClick("journal", "/logbook")}*/}
          {/*>*/}
          {/*  Rapports Journaliers*/}
          {/*</Menu.Item>*/}
            <Menu.Item
                name='requisitions-items'
                active= {activeItem === 'requisitions-items'}
                onClick={() => handleClick("requisitions-items", "/requisitions-items")}
            >
                Items à recevoir
            </Menu.Item>
          <Menu.Item
            name='journal'
            active= {activeItem === 'journal'}
            onClick={() => window.open(`https://unibec-fmp.solutionem.com/fmi/webd`, "_blank")}
          >
            Administration
          </Menu.Item>
            <Menu.Item
                name='aide'
                active= {activeItem === 'aide'}
                onClick={() => handleClick("aide", "/aide")}
            >
                Aide
            </Menu.Item>
          <Menu.Menu position='right'>
            <Menu.Item
              position='right'
              onClick={handelClickOnUserNenu}
            >
              <Icon name={'user'}
              />
              <div>
                {
                  user ? user.email: "please log in"

                }
              </div>
            </Menu.Item>
            <Menu.Item
                position='right'
                name='quitter'
                active= {activeItem === 'quitter'}
                onClick={() => handleExit()}
            >
                Quitter
            </Menu.Item>
          </Menu.Menu>
        </>
    )
}

const TopMenu = () => {
    let [dropdownMenuStyle, setDropdownMenuStyle] = useState({ display: "none" });
    const handleToggleDropdownMenu = () => {
        if (dropdownMenuStyle.display === "none") {
            return  { display: "flex" };
        } else {
            return  { display: "none" };
        }

};
    return(
        <>
            <Grid padded className="tablet computer only">
                <Menu fixed="top" size="huge">
                    <Container>
                       <AppMenuHeader/>
                       <AppMenuItems/>
                    </Container>
                </Menu>
            </Grid>
            <Grid padded className="mobile only">
                <Menu borderless fluid fixed="top" size="huge">
                    <AppMenuHeader/>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <Button icon basic toggle onClick={() => setDropdownMenuStyle(handleToggleDropdownMenu())}>
                                <Icon name="content" />
                            </Button>
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu vertical borderless fluid style={dropdownMenuStyle}>
                        <AppMenuItems/>
                    </Menu>
                </Menu>
            </Grid>
        </>
    );
}

export default TopMenu