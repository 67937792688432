import axios from "axios";

export const apiSrv = `https://services.solutionem.com/unibec`
// export const apiSrv = `http://localhost:3000/unibec`
export const clientLogo = '../'
// export const clientLogo = '/Users/manusoft/Apps/fournierapp/public/unibecLogo.png'

//https://www.fournierindustries.com/wp-content/uploads/2015/08/logo_inductriesfournier.png

export async function apiLogIn(user, pass) {
	const authorizationHeader = "Basic " + Buffer.from(`${user}:${pass}`, 'utf8').toString('base64')
	
	
	return await axios.post(`${apiSrv}/login`,{},{headers: {'Authorization': authorizationHeader } }	)
		.then(user => {
			
			return user.data
		})
		.catch(err => {
			
			return err.response.data
		})
}

export function apiLogOut (){
	
	return null
}


export async function apiGetSTM(user){
	return axios.get(`${apiSrv}/stm`, {headers : {"Authorization" : "Bearer " + user.token}}).then((res) => {
			
			res.data.data.map(item => item.fieldData)
		},
		(error) =>  error)
		.catch(err => err)
}