import React, {useState, useContext, useEffect} from "react";
import {UserContext, DepartementsContext, ListeStmContext} from "../../context/appContext";
import axios from 'axios'
import {apiSrv} from "../../config/api-srv";

const useHomePage = () => {
    const [dataIsLoaded, setDataIsLoaded] = useState(false)
    const {user} = useContext(UserContext)
    const {listeStm, setListeStm} = useContext(ListeStmContext)
    const {departements, setDepartements} = useContext(DepartementsContext)
    //
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        axios.get(`${apiSrv}/stm`, {headers : {"Authorization" : "Bearer " + user.token}})
            .then((res) => {

                    setListeStm(res.data.data.map(item => item.fieldData))
                },
                (error) => {
                    // setError(error);
                }).catch(err => err);
        axios.get(`${apiSrv}/departements`, {headers : {"Authorization" : "Bearer " + user.token}})
            .then((res) => {
                    setDepartements(res.data)},
                (error) => {
                    // setError(error);
                }).catch(err => err);

    }, [])

    useEffect(()=> {
        if (listeStm.length !== 0 && departements.length !==0 && !dataIsLoaded) {
            setDataIsLoaded(true)
        }
    },[listeStm, departements, dataIsLoaded])
    //
    return {user, dataIsLoaded}
}

export default useHomePage